.header {
    height: 86px;
    background-image: linear-gradient(to right,#3A7CB3, #19669B, #005488);;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
}
.navlink {
    color: #ffffff;
    margin: 0px 13px;
    text-decoration: none;
    font-size: 16px;
    position: relative;
}
.navlinkContainer .navlink:first-child {
    margin-left: 0px;
}
.navlinkContainer .navlink:last-child {
    margin-right: 0px;
}
/* .activeNavLink::before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    height: 0.7px;
    bottom: -5px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
} */
.activeNavLink{
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 4px;
}
.userNameCircle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border: 0.2px solid #626262;
    box-shadow: 0px 2px 20px #25265e1a;
    text-align: center;
}
.userOptionsCont{
    background-color: #FFF;
    font: normal normal 600 9px/13px Open Sans Regular;
    padding:6px 15px;
    border-radius:2px;
    text-align: center;
    position: absolute;
    right: 25px;
    box-shadow: -1px 2px 10px #D3D3D3;
    top: 60px;
    display: none;
}
.userOptionsCont:after {
    content: "";
  position: absolute;
  bottom: 100%;
  left: 75%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #FFF transparent;
  }
.userOptionsCont ul{
    list-style: none;
    margin:0px;
    padding: 0px;
    color: #037DB8;
}
.prof-nm{display: flex; flex-direction: row;}
.prof-nm .fa-icon{padding-left: 10px; margin: auto; color: #FFF; font-size: 20px;}
.signout{
    border: none;
    background: none;
    font: normal normal 600 9px/13px Open Sans Regular;
    color: #037DB8;
    padding: 0px;
    cursor: pointer;
}
.headerLogo {
    width: 132px;
}
.userNameInitials {
    letter-spacing: 0.07px;
    color: #727272;
    font: normal normal 600 14px/18px Open Sans;
    vertical-align: middle;
}