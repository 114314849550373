.footer {
    position: fixed;
    width: 100%;
    background-color: #6f6f6f;
    height: 60px;
    font-size: 16px;
    left: 0;
    bottom: 0;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer a {
    color: #ffffff;
}